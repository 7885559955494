/* You can add global styles to this file, and also import other style files */
::-webkit-scrollbar {
  display: none;
}
html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body,
html {
  background: #37474f;
  font-size: 14px;
}

@media screen and (max-width: 599px) {
  body {
    margin-left: 0;
    margin-right: 0;
  }
}
